<div class="modal-body">
  <div class="premium-feature_msg-container">
    <h2>
      {{ 'premiumFeatureModal.title' | translate }}
    </h2>

    <p>
      {{ 'premiumFeatureModal.message' | translate }}
    </p>

    <div class="premium-feature_buttons">
      <button class="btn btn-primary premium-feature_contact-btn">
        <img appAssetSrc="assets/svg/common/diamond.svg" />
        <span>{{ 'premiumFeatureModal.buttons.contactUs' | translate }}</span>
      </button>
      <button
        class="btn btn-link premium-feature_not-now-btn"
        (click)="activeModal.close()"
      >
        {{ 'premiumFeatureModal.buttons.notNow' | translate }}
      </button>
    </div>
  </div>
  <div class="premium-feature_img-container">
    @if (graphicUrl) {
      <img [appAssetSrc]="graphicUrl" />
    }
  </div>
</div>
