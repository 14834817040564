import { inject, Injectable } from '@angular/core';

import { getBrowserTimezoneOffsetInHours } from '@core/helpers/get-browser-timezone-offset';
import { DateService } from '@core/services/date.service';

import { type InternationalSettings, type ThousandsSeparator } from '../models/internationalSettings';

@Injectable({
  providedIn: 'root',
})
export class LocalizationService {
  public timezoneOffset: number;
  public currentLocale: string;

  private numberFormatter: Intl.NumberFormat;

  private readonly formatDateService = inject(DateService);

  init(settings?: InternationalSettings): void {
    this.currentLocale = navigator.language;

    if (!settings) {
      this.formatDateService.setDateOrder('MonthDayYear');
      this.formatDateService.setTimeRepresentation('Hour12');
      this.formatDateService.setTimeZoneOffsetHours(getBrowserTimezoneOffsetInHours());

      this.initNumberFormatter('Decimal');
      return;
    }

    this.formatDateService.setDateOrder(settings.dateFormat);
    this.formatDateService.setTimeRepresentation(settings.timeFormat);
    this.formatDateService.setTimeZoneOffsetHours(settings.offsetInHours);

    this.initNumberFormatter(settings.thousandsSeparator);
  }

  localizeNumber(number: number): string {
    return this.numberFormatter.format(number);
  }

  private initNumberFormatter(thousandsSeparator: ThousandsSeparator): void {
    this.numberFormatter = new Intl.NumberFormat(thousandsSeparator === 'Decimal' ? 'en-US' : 'it');
  }
}
