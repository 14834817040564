import { Injectable } from '@angular/core';

import { ConfigService } from './config.service';
import { type User } from '../models/user';

declare const splitio: any;

const SPLIT_NAMES = [];

@Injectable()
export class SplitService {
  private client: any;
  private factory: any;
  private isClientInit: boolean;
  public featureFlags: Record<string, string>;

  public async initClient(user: User) {
    if (this.isClientInit && !!this.client) {
      await this.client.destroy();
    }

    if (!ConfigService.settings) {
      setTimeout(() => {
        this.initClient(user);
      }, 100);
      return;
    }

    this.factory = splitio({
      core: {
        authorizationKey: ConfigService.settings.splitApiKey,
        key: user.id,
      },
    });
    this.client = this.factory.client();

    this.client.on(this.client.Event.SDK_READY, () => {
      this.isClientInit = true;
      this.featureFlags = this.client.getTreatments(SPLIT_NAMES, {
        role: user.roles[0],
      });
    });
  }

  public async destroyClient(): Promise<any> {
    if (!this.isClientInit || !this.client) {
      await Promise.resolve();
      return;
    }

    this.isClientInit = false;
    return this.client.destroy();
  }
}
