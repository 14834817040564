import { Component, Input, inject } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';

import { AssetSrcDirective } from '@core/directives/asset-src.directive';

@Component({
  selector: 'premium-feature-modal',
  templateUrl: './premium-feature-modal.component.html',
  styleUrls: ['./premium-feature-modal.component.scss'],
  standalone: true,
  imports: [AssetSrcDirective, TranslateModule],
})
export class PremiumFeatureModalComponent {
  public readonly activeModal = inject(NgbActiveModal);
  @Input() graphicUrl: string;
}
